import {useState} from "react";
import {Col, Drawer, Row} from "antd";
import {TFunction, withTranslation} from "react-i18next";
import Container from "../../common/Container";
import {
  Burger,
  CustomNavLinkSmall,
  HeaderSection,
  Label,
  LogoContainer,
  Menu,
  NotHidden,
  Outline,
  Span,
} from "./styles";
import {useHistory, useLocation} from "react-router-dom";

const Header = ({t}: { t: TFunction }) => {
    const [visible, setVisibility] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const toggleButton = () => {
        setVisibility(!visible);
    };
    const navigateToDocs = () => {
        history.push("/docs");
        setVisibility(false);
    };

    const scrollTo = (id: string) => {
        const scrollToElement = () => {
            const element = document.getElementById(id) as HTMLDivElement;
            element.scrollIntoView({
                behavior: "smooth",
            });
        };

        if (location.pathname !== "/" && location.pathname !== "/home") {
            history.push("/");
            setTimeout(scrollToElement, 0); // Adjust the timeout as needed
        } else {
            scrollToElement();
        }
    };
    const MenuItem = () => {
        const scrollTo = (id: string) => {
            const scrollToElement = () => {
                const element = document.getElementById(id) as HTMLDivElement;
                element.scrollIntoView({
                    behavior: "smooth",
                });
            };

            if (location.pathname !== "/" && location.pathname !== "/home") {
                history.push("/");
                setTimeout(scrollToElement, 0); // You might need to adjust the timeout
            } else {
                scrollToElement();
            }
        };
        return (
            <>
                <CustomNavLinkSmall onClick={() => {
                    history.push("/home");
                    setVisibility(false)
                }}>
                    <Span>{t("Jodi App")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => {
                    history.push("/mission");
                    setVisibility(false)
                }}>
                    <Span>{t("Our Mission")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => {
                    history.push("/blog");
                    setVisibility(false)
                }}>
                    <Span>{t("Blog")}</Span>
                </CustomNavLinkSmall>
                {/*<CustomNavLinkSmall onClick={() => scrollTo("events")}>*/}
                {/*  <Span>{t("Events")}</Span>*/}
                {/*</CustomNavLinkSmall>*/}
                {/*<CustomNavLinkSmall onClick={() => {history.push("/docs")}}>*/}
                {/*  <Span>{t("Docs")}</Span>*/}
                {/*</CustomNavLinkSmall>*/}
                {/*<CustomNavLinkSmall*/}
                {/*  style={{ width: "180px" }}*/}
                {/*  onClick={() => scrollTo("contact")}*/}
                {/*>*/}
                {/*  <Span>*/}
                {/*    <Button>{t("Join the Waitlist")}</Button>*/}
                {/*  </Span>*/}
                {/*</CustomNavLinkSmall>*/}
            </>
        );
    };

    return (
        <HeaderSection>
            <Container>
                <Row justify="space-between">
                    <LogoContainer to="/" aria-label="homepage"
                                   style={{justifyContent: 'center', alignItems: 'center'}}>
                        <img src={require("./JodiLogo.png")} alt="Tenjin" style={{height: 40}}/>
                        <img src={require("./JodiText.png")} alt="Tenjin" style={{height: 50}}/>
                    </LogoContainer>
                    <NotHidden>
                        <MenuItem/>
                    </NotHidden>
                    <Burger onClick={toggleButton}>
                        <Outline/>
                    </Burger>
                </Row>
                <Drawer closable={false} open={visible} onClose={toggleButton}>
                    <Col style={{marginBottom: "2.5rem"}}>
                        <Label onClick={toggleButton}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline/>
                            </Col>
                        </Label>
                    </Col>
                    <MenuItem/>
                </Drawer>
            </Container>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
