import {Col, Row} from "antd";
import {TFunction, withTranslation} from "react-i18next";
import {SvgIcon} from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {Extra, LogoContainer, NavLink,} from "./styles";

interface SocialLinkProps {
    href: string;
    src: string;
}

const Footer = ({t}: { t: TFunction }) => {
    const handleChange = (language: string) => {
        i18n.changeLanguage(language);
    };

    const SocialLink = ({href, src}: SocialLinkProps) => {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width="25px" height="25px"/>
            </a>
        );
    };

    return (
        <>
            <Extra>
                <Container border={true}>
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{paddingTop: "3rem"}}
                    >
                        <NavLink to="/">
                            <LogoContainer aria-label="homepage">
                                <img src={require("../Header/JodiLogo.png")} alt="Jodi" style={{height: 40}}/>
                                <img src={require("../Header/JodiText.png")} alt="Jodi" style={{height: 50}}/>
                            </LogoContainer>
                        </NavLink>
                    </Row>

                    {/* Privacy Policy Link */}
                    <Row style={{paddingTop: "2rem", paddingBottom: "2rem"}}>
                        <Col>
                            <a
                                href="/JodiPrivacyPolicy.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{fontWeight: "bold", textDecoration: "underline"}}
                            >
                                {t("Privacy Policy")}
                            </a>
                        </Col>
                    </Row>

                </Container>
            </Extra>
        </>
    );
};

export default withTranslation()(Footer);
