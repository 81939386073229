const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  // {
  //   path: ["/", "/docs"],
  //   exact: true,
  //   component: "Docs",
  // },
  {
    path: ["/", "/blog"],
    exact: true,
    component: "Blog",
  },
  {
    path: ["/", "/mission"],
    exact: true,
    component: "Mission",
  },
];

export default routes;
